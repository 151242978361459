import React from 'react';
import { Button } from '../ButtonElement';
import { InfoContainer,
     InfoWrapper,
      InfoRow,
       Column1,
     Column2,
     TextWrapper,
     TopLine,
      Heading,
      Subtitle,
      BtnWrap,
      ImgWrap, Img } from './InfoElements';
import { Reveal, Tween } from 'react-gsap';

const InfoSection = ({ 
    lightBg,
     id,
     imgStart,
     topLine,
     lightText,
     headLine,
     darkText,
     description,
     buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2 }) => {

        const FadeInLeft = ({ children }) => (
            <Tween
              from={{ opacity: 0, transform: 'translate3d(-100vw, 0, 0)' }}
              ease="back.out(0.3)"
            >
              {children}
            </Tween>
          );

    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>  
                    
                  
            
                    <InfoRow imgStart={imgStart}>
  

                        <Column1>
                      
                            <TextWrapper>
                                <TopLine>
                                    {topLine}
                                </TopLine>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle>
                                <BtnWrap>
                                    <Button to='contact'
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                    > 
                                    {buttonLabel}
                                    
                                 </Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        
                        <Reveal repeat trigger={<div />}>
  <FadeInLeft><Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                        </FadeInLeft>
</Reveal>
                    </InfoRow>
                    
  

                </InfoWrapper>

            </InfoContainer>
        </>
    )
}

export default InfoSection
