
export const homeObjServices = {
    id: 'catchy',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Noch fragen?',
    headLine: 'Zögern Sie nicht',
    description: 'Sie können uns jederzeit kontaktieren bei einer Frage zu einer Dienstleistung, es ist auch Möglich eine unverbindliche Offerte anzufordern.',
    buttonLabel: 'Kontakt',
    imgStart: true,
    img: require('../../images/info.svg').default,
    alt: 'beshcreibung bild',
    dark: false,
    primary: false,
    darkText: true

};

// export const homeObjOne = {
//     id: 'about',
//     lightBg: false,
//     lightText: true,
//     lightTextDesc: true,
//     topLine: 'Premium Bank',
//     headLine: 'Lorem Ipsum no amanssn sds',
//     description: 'description of the alsfafasnsmddjehfehbfenfds ',
//     buttonLabel: 'Hell Button',
//     imgStart: true,
//     img: require('../../images/cartow.svg').default,
//     alt: 'Car',
//     dark: true,
//     primary: true,
//     darkText: false,
// };



// export const homeObjThree = {
//     id: 'about',
//     lightBg: false,
//     lightText: true,
//     lightTextDesc: true,
//     topLine: 'Premium Bank',
//     headLine: 'Lorem Ipsum no amanssn sds',
//     description: 'description of the alsfafasnsmddjehfehbfenfds ',
//     buttonLabel: 'Hell Button',
//     imgStart: true,
//     img: require('../../images/svg1.svg').default,
//     alt: 'Car',
//     dark: true,
//     primary: true,
//     darkText: false

// };