import React from 'react'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    // FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIconLink, SocialIcons
} from './FooterElements';

import {
  FaFacebook
//   FaInstagram
} from 'react-icons/fa';
// 



const Footer = () => {

    return (
        <FooterContainer id="zeiten">
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Adresse</FooterLinkTitle>
                            <ul>
                                <li>
                                    <a href="//www.google.com/maps/dir//46.6659284,7.5995694/@46.665928,7.599569,15z?hl=de" target="_blank" rel="noreferrer" > 
                                    <p>Garage AutoJaro GmbH<br />Burgholz 52<br />3753 Oey</p>
                                    </a>
                                </li>
                            </ul>
                        </FooterLinkItems>

                        <FooterLinkItems>
                        <FooterLinkTitle>Öffnungszeiten</FooterLinkTitle>
                        <ul>
                               <li><p>Montag: 08:00 -  17:30 Uhr</p></li>
                               <li><p>Dienstag: 08:00 - 17:30</p></li>
                               <li><p>Mittwoch: 08:00 - 17:30 Uhr</p></li>
                               <li><p>Donnerstag: 08:00 - 17:30 Uhr</p></li>
                               <li><p>Freitag: 08:00 - 17:30 Uhr</p></li>
                               <li><p>Samstag: auf Anfrage</p></li>
                             

                            </ul>



                    </FooterLinkItems>
                      



                    </FooterLinksWrapper>

                    <FooterLinksWrapper>
                        
                    <FooterLinkItems>
                            <FooterLinkTitle>Telefon</FooterLinkTitle>
                            <ul>
                                <li>Garage:<br /> <a href="tel:+41795825346">079 582 53 46</a>   </li>
                                <br />
                                <li>Verkauf:<br /><a href="tel:+41793341713">079 334 17 13 </a> </li><br />
                            </ul>

<ul>
    <li>
        Weiters: 
    </li>
    <li><a href='/impressum' target="_blank">Impressum</a></li>
</ul>
                        </FooterLinkItems>



                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to="/">
                            AutoJaro
                        </SocialLogo> 
                        <WebsiteRights>AutoJaro © {new Date().getFullYear()}</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//www.facebook.com/autojarogarage/" rel="noreferrer"  target="_blank" aria-label="Facebook" >
                                <FaFacebook size="2.5em"/>
                            </SocialIconLink>

                            {/* <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink> */}
                            
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
