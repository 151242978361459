import React from 'react'
import Impressum from '../components/Impressum'
import ScrollToTop from '../components/ScrollToTop'

const Impressumpage = () => {
    return (
       <>
       <ScrollToTop />
       <Impressum />
       </>
    )
}

export default Impressumpage
