import styled from 'styled-components';

export const ContactContainer = styled.div`
    height:800px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.7);
    
    background-color: #4b0000;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%234b0000'/%3E%3Cstop offset='1' stop-color='%232c0900'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23ca7e40' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23ca7e40' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23ca7e40' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23ca7e40' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.78'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
background-position: center;



 @media screen and (max-width: 1300px) {
        height: 945px;
    }  

    
    @media screen and (max-width: 700px) {
        height: 1350px;
    }
    
`
export const ContactIframe = styled.div`
height:500px;
width:600px;

@media screen and (max-width: 1199px) {
        height:300px;
        width:auto;
        }
`
export const ContactWrapper = styled.div`
        max-width: 1000px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        justify-content:center;
        grid-gap: 16px;
        padding: 0 50px;



        @media screen and (max-width: 1199px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            padding: 0 20px;

        }
`

export const ContactCard = styled.div`
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        max-height: 540px;
        padding: 30px;
        box-shadow: 0 1px 3px rgba(0,0,0,0,.2);
        transition: all 0.2s ease-in-out;

    
`

export const ContactIcon = styled.img`
        height: 160px;
        width: 160px;
        margin-bottom: 10px;
`

export const ContactH1 = styled.h1`
        font-size: 2.5rem;
        color: #fff;
        margin-bottom: 64px;

        @media screen and (max-width: 480px){
        font-size: 2rem;

        }
`

export const ContactH2 = styled.h2`
        font-size: 1em;
        margin-bottom: 10px;
`

export const ContactP = styled.p`

        font-size: 1rem;
        text-align: center;
`

