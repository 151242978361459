import React from 'react'
import Icon1 from '../../images/svg1.svg';

import Cartow from '../../images/cartow.svg'
import Cash from '../../images/cash.svg'
import {
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesP
} from './ServicesElements'




const Services = () => {


    return (
        <>

  

            <ServicesContainer id="services">
                <ServicesH1>Dienstleistungen</ServicesH1>


                <ServicesWrapper>


                   
                            <ServicesCard>
                                <ServicesIcon src={Icon1} />


                                <ServicesH2>Gewöhnliche Arbeiten</ServicesH2>
                                <ServicesP>   <ul>
                                    <li>MFK</li>

                                    <li>Service</li>

                                    <li>Frontscheibenwechsel</li>

                                    <li>Rost</li>

                                    <li>Schweissarbeiten
    </li>

                                    <li>Pneuservice
    </li>

                                    <li>Carroserie&nbsp;
    </li>

                                    <li>Malerarbeiten
    </li>
                                </ul></ServicesP>


                            </ServicesCard>
                      


                    <ServicesCard>
                        <ServicesIcon src={Cartow} />
                        <ServicesH2>Aber auch andere Anliegen</ServicesH2>
                        <ServicesP>Die Garage für alle Marken
                        Reperaturen, Inspektionen, MFK Prüfung,
Service rund um Ihr Auto. Hohe Qualität ohne Wenn und aber, mit Preisen die sich sehen lassen können.</ServicesP>
                    </ServicesCard>

                    
                    
                    <ServicesCard>
                        <ServicesIcon src={Cash} />
                        <ServicesH2>Felgen</ServicesH2>
                        <ServicesP>
                  
                            <p>Räder wechseln:<br /> 40.- CHF</p>
                            <p>Pneu wechseln:<br /> 25.- CHF</p>
                            <h4>Stahlfelgen</h4>
                            <p>Pneu wechseln:<br /> 23.- CHF</p>

                        </ServicesP>
                       
                    </ServicesCard>
                    
                    
                </ServicesWrapper>
            </ServicesContainer>
          
        </>
    )
}

export default Services
