import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper,
     SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute} from './SidebarElements';
const Sidebar = ({isOpen, toggle}) => {
    return (
   <SidebarContainer isOpen={isOpen} onClick={toggle} >
       <Icon onClick={toggle}>
           <CloseIcon />
       </Icon>
       <SidebarWrapper>
           <SidebarMenu>
               <SidebarLink to="services"
                            smooth={true} 
                            duration={650} 
                            spy={true} 
                            exact="true" 
                            offset={60}
                            onClick={toggle}>
               Dienstleistungen
               </SidebarLink>
          
               <SidebarLink to="contact" 
                            smooth={true} 
                            duration={650} 
                            spy={true} 
                            exact="true" 
                            offset={-80}
                            onClick={toggle}>
               Kontakt
               </SidebarLink>
               <SidebarLink to="zeiten"
                            smooth={true} 
                            duration={650} 
                            spy={true} 
                            exact="true" 
                            offset={60}
                            onClick={toggle}>
                   Öffnungszeiten
               </SidebarLink>
           </SidebarMenu>

           <SideBtnWrap>
               <SidebarRoute to={{ pathname: "//www.autoscout24.ch/de/ip/auto-jaro-3645-gwatt?accountid=63505" }} target="_blank" >
                   Auto Verkauf
               </SidebarRoute>
           </SideBtnWrap>
       </SidebarWrapper>
   </SidebarContainer>
    );
};

export default Sidebar;
