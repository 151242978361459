import React, {useState} from 'react'
import {HeroContainer, HeroBg, VideoBg
, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight} from './HeroElements';
import Video from '../../videos/video.mp4';
import {Button} from '../ButtonElement';
import { Reveal, Tween } from 'react-gsap';

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    return (
        <>
   
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
            </HeroBg>
            <HeroContent>
               
                <Reveal repeat>
  <Tween from={{ opacity: 0.3 }} duration={2.5}>
  <HeroH1>AutoJaro GmbH</HeroH1>
    </Tween>
</Reveal>
                  
                
                <Reveal repeat>
  <Tween from={{ opacity: 0 }} duration={4.5}>
    
                <HeroP>
                    Ihr Auto-Experte für Service im Berner Oberland.
                    Die Garage für alle Marken
Reperaturen, Inspektionen
MFK Prüfung
Service rund um Ihr Auto. Hohe Qualität ohne Wenn und aber, mit Preisen die sich sehen lassen können.

                </HeroP>
                </Tween>
</Reveal>
                <HeroBtnWrapper>
        
                    <Button to="contact" onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'
                    smooth={true} 
                    duration={600} 
                    spy={true} 
                    exact="true" >
                        So finden Sie uns {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
        </>
    )
}

export default HeroSection
