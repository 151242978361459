import React from 'react'
import { Container, Icon, ImpressumButton, ImpressumContent, ImpressumH1, ImpressumWrap, Text} from './ImpressumElements'

function Impressum() {
    return (
      <>
      <Container>
          <ImpressumWrap>
              <Icon to="/">AutoJaro</Icon>
              <ImpressumContent>
                  <ImpressumH1>Impressum</ImpressumH1>
<Text>test</Text>
<ImpressumButton >sffsf</ImpressumButton>
              </ImpressumContent>
          </ImpressumWrap>
      </Container>
      </>
    )
}

export default Impressum;
