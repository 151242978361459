import React, { useState, useEffect} from 'react'
import { FaBars } from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll';
import {
    Nav, NavbarContainer, NavLogo, NavLogoLogo, MobileIcon,
    NavMenu, NavItem, NavLinks,
    NavBtn, NavBtnLink
} from './NavbarElements';
import Logo from '../../images/logo.png'
import { IconContext } from 'react-icons/lib';

const Navbar = ({ toggle }) => {
    const[scrollNav, setScrollNav] = useState(false)

    const changeNav = ()=> {
        if(window.scrollY >= 80){
            setScrollNav(true)
        } else{
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }
 

    return (
        <>
        <IconContext.Provider value={{color: '#fff' }}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to="/" onClick={toggleHome}>
                       <NavLogoLogo src={Logo} />
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    
                    <NavMenu>
                   

                        <NavItem>
                            <NavLinks to="services" 
                            smooth={true} 
                            duration={800} 
                            spy={true} 
                            exact="true" 
                            offset={80} 
                        
                            >
                                Dienstleistungen
                            </NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks to="contact"  
                            smooth={true} 
                            duration={800} 
                            spy={true}
                            offset={-59} 
                            exact="true" 
                            >
                                Kontakt
                            </NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks to="zeiten"  
                            smooth={true} 
                            duration={800} 
                            spy={true} 
                            exact="true" 
                             >
                                Öffnungszeiten
                            </NavLinks>
                        </NavItem>
                      
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to={{ pathname: "//www.autoscout24.ch/de/ip/auto-jaro-3645-gwatt?accountid=63505" }} target="_blank" >
                            Autoverkauf
                            </NavBtnLink>
                            
                        
                    </NavBtn>
                </NavbarContainer>
            </Nav>
            </IconContext.Provider>
        </>
    );
};

export default Navbar;
